import { useEffect, useState, KeyboardEvent } from 'react'
import {
  selectDeparture,
  selectPhoneNumber,
  setDeparture,
  setPhoneNumber,
} from '../state/orderSlice'
import { useAppDispatch, useAppSelector } from '../state/store'
import { selectCoord } from '../state/userSlice'
import KakaoPlace from '../types/kakaoPlace'

type Props = { className: string }

const DepartureSearch = ({ className }: Props) => {
  const [departureQuery, setDepartureQuery] = useState('')
  const [index, setIndex] = useState(0)
  const [kPlaces, setKPlaces] = useState<KakaoPlace[]>([])
  const [timer, setTimer] = useState<any>(null)
  const coord = useAppSelector(selectCoord)
  const phoneNumber = useAppSelector(selectPhoneNumber)

  const dispatch = useAppDispatch()
  const departure = useAppSelector(selectDeparture)

  useEffect(() => {
    if (departure) {
      setDepartureQuery('')
    }
  }, [departure])

  useEffect(() => {
    setKPlaces([])
    setIndex(0)

    if (departureQuery.trim() === '') {
      clearTimeout(timer)
      return
    }
    searchSubs()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departureQuery])

  const searchSubs = () => {
    clearTimeout(timer)
    setTimer(
      setTimeout(() => {
        const places = new kakao.maps.services.Places()
        const options = coord ? { x: coord.longitude, y: coord.latitude } : {}
        const callback = (
          result: kakao.maps.services.PlacesSearchResult,
          status: kakao.maps.services.Status,
        ) => {
          if (status === kakao.maps.services.Status.OK) {
            const top10 = result.filter((_, index) => index < 10) as unknown as KakaoPlace[]
            setKPlaces(top10)
          }
        }
        places.keywordSearch(departureQuery, callback, options)
      }, 250),
    )
  }

  if (departure)
    return (
      <div className={className}>
        <label htmlFor="destination" className="text-xs text-gray-700">
          출발지 <span className="text-red">*</span>
        </label>
        <div className="w-full p-2 text-gray-500 border border-gray-400 rounded" id="departure">
          <div className="mb-2 leading-5 text-gray-700">{departure.name}</div>
          <div className="text-xs">{departure.roadAddress}</div>
          <div className="text-xs">{departure.address}</div>

          <div className="flex items-center justify-between gap-2 mt-2">
            <div className="text-xs font-semibold">{phoneNumber}</div>
            <button
              className="px-2 py-1 text-xs text-white bg-gray-500 rounded-sm"
              onClick={() => {
                dispatch(setDeparture(undefined))
                dispatch(setPhoneNumber(''))
              }}>
              재설정
            </button>
          </div>
        </div>
      </div>
    )

  const handleKeyMove = (event: KeyboardEvent<HTMLInputElement>) => {
    // event.preventDefault()
    if (kPlaces.length === 0 || event.nativeEvent.isComposing) return

    if (event.key === 'ArrowUp') {
      if (index > 0) setIndex(i => i - 1)
    }
    if (event.key === 'ArrowDown') {
      if (index < kPlaces.length - 1) setIndex(i => i + 1)
    }

    if (event.key === 'Enter') {
      const kp = kPlaces[index]
      dispatch(setDeparture(kp))
    }
  }
  return (
    <div className={className}>
      <label htmlFor="destination" className="text-xs text-gray-700">
        출발지 <span className="text-red">*</span>
      </label>
      <input
        id="departure"
        type="text"
        placeholder="장소, 주소, 전화번호 검색"
        className="w-full p-2 border border-gray-400 rounded focus:outline-none placeholder:text-sm"
        value={departureQuery}
        onChange={e => {
          setDepartureQuery(e.target.value)
        }}
        onFocus={e => e.target.select()}
        onKeyDown={handleKeyMove}
      />
      {kPlaces.length > 0 && (
        <div className="absolute w-full bg-white border border-gray-400 top-16">
          {kPlaces.map((kp, idx) => (
            <button
              key={kp.id}
              id={`sub-${idx}`}
              onClick={e => {
                e.preventDefault()
                const kp = kPlaces[idx]
                dispatch(setDeparture(kp))
              }}
              className={`block w-full p-2 tart hover:bg-gray-200 focus:bg-gray-200 focus:outline-none hover:cursor-pointer ${
                index === idx ? 'bg-gray-200' : ''
              }`}>
              <div className="text-sm text-left">{kp.place_name}</div>
              <div className="text-xs text-left">{kp.road_address_name}</div>
              <div className="text-xs text-left">{kp.phone}</div>
            </button>
          ))}
        </div>
      )}
    </div>
  )
}

export default DepartureSearch
