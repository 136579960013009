import React, { useCallback, useEffect, useState } from "react";
import {
  selectDeparture,
  selectDestination,
  selectPhoneNumber,
  setPhoneNumber,
} from "../state/orderSlice";
import { useAppDispatch, useAppSelector } from "../state/store";

type Props = { disabled: boolean; className: string };

const PhoneNumberInput = ({ disabled, className }: Props) => {
  const dispatch = useAppDispatch();
  const departure = useAppSelector(selectDeparture);
  const destination = useAppSelector(selectDestination);
  const phoneNumber = useAppSelector(selectPhoneNumber);
  const shouldShow = departure && !destination && !phoneNumber;
  const [phone, setPhone] = useState<string>(phoneNumber);

  useEffect(() => {
    if (!destination) setPhone("");
  }, [destination]);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const regex = /^[0-9\b -]{0,13}$/;
    if (regex.test(e.target.value)) {
      setPhone(e.target.value);
    }
  }, []);

  useEffect(() => {
    if (phone.length === 4 && phone.startsWith("01")) {
      setPhone(phone.replace(/(\d{3})(\d{1})/, "$1-$2"));
    }
    if (phone.length === 9 && phone.startsWith("01")) {
      setPhone(phone.replace(/(\d{3}-)(\d{4})(\d+)/, "$1$2-$3"));
    }

    if (phone.length === 10) {
      setPhone(phone.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3"));
    }

    if (phone.length === 11) {
      setPhone(phone.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3"));
    }

    if (phone.length === 13) {
      setPhone(
        phone.replace(/-/g, "").replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3")
      );
    }
  }, [phone]);

  // TODO: validate phone number format
  const registerPhoneNumber = useCallback(() => {
    dispatch(setPhoneNumber(phone));
  }, [dispatch, phone]);

  if (!shouldShow) return null;

  return (
    <div className={className}>
      <label htmlFor="phoneNumber" className="text-xs text-gray-700">
        전화번호 <span className="text-red">*</span>
      </label>
      <div className="relative">
        <input
          disabled={disabled}
          autoFocus
          id="phoneNumber"
          type="text"
          className="w-full p-2 border border-gray-400 rounded focus:outline-none placeholder:text-sm"
          value={phone}
          placeholder="호출담당자 연락처"
          // onChange={e => setDestinationQuery(e.target.value)}
          // onFocus={e => e.target.select()}
          onChange={(e) => handleChange(e)}
          onKeyDown={(e) => {
            if (e.key === "Enter") registerPhoneNumber();
          }}
        />
        <div className="absolute bottom-0 flex items-center justify-end h-full right-2">
          <button
            className="px-2 py-1 text-xs text-white bg-red rounded-sm"
            onClick={registerPhoneNumber}
          >
            확인
          </button>
        </div>
      </div>
    </div>
  );
};

export default PhoneNumberInput;
