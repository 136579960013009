import Coord from './Coord'
import Place from './Place'
import User from './User'

type Order = {
  id?: number
  createdAt: string
  updatedAt: string
  driverMatchedAt?: string
  heroMatchedAt?: string
  heroPickUpedAt?: string
  departedAt?: string
  loadedAt?: string
  arrivedAt?: string
  feeCaculatedAt?: string
  completedAt?: string
  clientPhoneNumber: string
  patientName: string
  patientPhoneNumber: string
  companionName?: string
  companionPhoneNumber?: string
  description?: string
  gear?: string
  etc?: string
  status: OrderStatus | string
  departure: Place
  destination: Place
  driver?: Driver
  hero?: User
  organization?: Organization
  invoice?: Invoice
  expectedFee?: number
}

interface Invoice {
  id: number
  type: string
  totalFee: number
  driverFee: number
  heroFee: number
  goochooriFee: number
  transferStartedAt: string
  transferFinishedAt: string
  calculatedAt: string
}

export default Order

export enum OrderStatus {
  NO_DRIVER = 'no-driver',
  PENDING = 'pending',
  STARTED = 'started',
  DRIVER_MATCHED = 'driver-matched',
  HERO_REQUESTED = 'hero-requested',
  HERO_REJECTED = 'hero-rejected',
  PICKUP_REQUESTED = 'pickup-requested',
  PICKUP_REJECTED = 'pickup-rejected',
  HERO_MATCHED = 'hero-matched',
  HERO_PICKUPED = 'hero-pickuped',
  LOADED = 'loaded',
  DEPARTED = 'departed',
  ARRIVED = 'arrived',
  FEE_REQUESTED = 'fee-requested',
  FEE_CALCULATED = 'fee-calculated',
  COMPLETED = 'completed',
  CANCELLED = 'canceled',
}

export interface Driver {
  id: number
  role: string
  name: string
  email: string
  phoneNumber: string
  licenseNumber?: any
  isp: string
  identifier?: any
  profileImage: string
  coord: Coord
  location: string
  status: string
  deletedAt?: any
  organization?: Organization
}

interface Organization {
  id: number
  type: string
  name: string
  licenseNumber: string
  profileImage: string
  email: string
  phoneNumber: string
  address: string
  coordinate: Coord
  point: string
  certificate: string
  affiliation: string
  isVerified: boolean
  createdAt: string
  updatedAt: string
}
