import React, { useEffect, useRef } from "react";

type Props = {
  isNecessary?: boolean;
  disabled: boolean;
  value: string;
  setValue: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className: string;
  id: string;
  label: string;
};

const TextInput = ({
  isNecessary = false,
  disabled,
  value,
  setValue,
  className,
  id,
  label,
}: Props) => {
  const inputRef = useRef<any>(null);

  useEffect(() => {
    if (id === "patientName" && !disabled) inputRef.current?.focus();
  }, [disabled, id]);

  return (
    <div className={className}>
      <label htmlFor={id} className="text-xs text-gray-700">
        {label} {isNecessary && <span className="text-red">*</span>}
      </label>
      <input
        ref={inputRef}
        disabled={disabled}
        id={id}
        type="text"
        className="w-full p-2 border border-gray-400 rounded focus:outline-none"
        value={value}
        onChange={setValue}
        onFocus={(e) => e.target.select()}
      />
    </div>
  );
};

export default TextInput;
