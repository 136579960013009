import dayjs from 'dayjs'
import React, { useCallback, useEffect, useState } from 'react'
import CustomCalendar from '../components/CustomCalendar'
import axios from '../api/axios'
import Order from '../types/Order'
import Pagination from '../components/Pagination'

function CallHistoryPags() {
  const [orders, setOrders] = useState<Order[]>([])
  const today = new Date()
  const [startDate, setStartDate] = useState(
    new Date(`${today.getFullYear()}-${today.getMonth() + 1}-1 `),
  )
  const [endDate, setEndDate] = useState(new Date())
  const [selectPage, setSelectPage] = useState(1)
  const [total, setTotal] = useState(0)

  const getOrder = useCallback(async () => {
    try {
      console.log(startDate, endDate)
      const res = await axios.get(
        `organization/order/${startDate}/${endDate}?page=${selectPage}&listNum=10`,
      )
      setOrders(res.data.orders[0])
      setTotal(res.data.orders[1])
    } catch (error) {
      console.log(error)
    }
  }, [endDate, selectPage, startDate])

  useEffect(() => {
    getOrder()
  }, [getOrder])

  const reset = () => {
    setStartDate(new Date(`${today.getFullYear()}-${today.getMonth() + 1}-1 `))
    setEndDate(today)
    setSelectPage(1)
  }

  return (
    <div className="p-8 flex flex-1 flex-col bg-background">
      <div className="bg-white py-5 px-8 flex-row flex rounded-lg">
        <div className="flex-1 flex flex-row w-full items-center">
          <span className="mr-3">조회기간</span>
          <CustomCalendar
            date={startDate}
            changeDate={setStartDate}
            setSelectPage={setSelectPage}
          />
          ~
          <CustomCalendar date={endDate} changeDate={setEndDate} setSelectPage={setSelectPage} />
        </div>
        <button onClick={reset} className="bg-blue-400 text-white py-1 px-6 rounded-lg">
          초기화
        </button>
      </div>

      <div className="bg-white mt-8 px-8 py-6 rounded-lg">
        <h2 className="mn-3">이송내역</h2>
        <table className="w-full rounded-lg overflow-hidden ">
          <thead>
            <tr className="bg-gray-200">
              <th className="py-2 w-8  text-center">id</th>
              <th className="py-2 w-24 text-center">환자명</th>
              <th className="py-2 w-56 ">도착병원</th>
              <th className="py-2 w-24 text-center">드라이버명</th>
              <th className="py-2 w-64 ">전원 사유</th>
              <th className="py-2 w-24 text-center">일시</th>
              <th className="py-2 w-14  text-center">상태</th>
            </tr>
          </thead>

          <tbody>
            {orders.map(order => (
              <tr key={order.id} className="text-sm text-gray-800 even:bg-gray-100">
                <td className="w-8 text-center py-2">{order.id}</td>
                <td className="text-center py-2">
                  {order.patientName?.replace(/(.)(.)(.*)/, '$1*$3')}
                </td>
                <td className="text-center py-2">{order.destination.name}</td>
                <td className="text-center py-2">{order.driver?.name || '-'}</td>
                <td className="text-center py-2">{order.description || '-'}</td>
                <td className="text-center py-2">
                  {dayjs().diff(dayjs(order.createdAt), 'hour') < 1
                    ? dayjs(order.createdAt).fromNow()
                    : dayjs().diff(dayjs(order.createdAt), 'hour') < 8
                    ? dayjs(order.createdAt).format('A h:mm')
                    : dayjs(order.createdAt).format('MM/DD HH:mm')}
                </td>
                <td className="text-center py-2">{order.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {total !== 0 && (
        <Pagination
          listNum={10}
          total={total}
          selectPage={selectPage}
          setSelectPage={setSelectPage}
        />
      )}
    </div>
  )
}

export default CallHistoryPags
