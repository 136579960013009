import axios from '../api/axios'
import { EOrderTarget } from '../lib/enum'

interface IProps {
  orderTarget: EOrderTarget
  onChange: (target: EOrderTarget) => void
}
const OrderTargetSwitch = ({ orderTarget, onChange }: IProps) => {
  return (
    <div
      className="flex items-center"
      style={{
        background: '#acacac',
        height: '40px',
        borderRadius: '8px',
      }}>
      <button
        style={{
          width: '170px',
          height: '100%',
          color: '#fff',
          borderRadius: '8px',
          background: orderTarget === EOrderTarget.PARTNER ? '#ff8571' : undefined,
        }}
        onClick={() => {
          onChange(EOrderTarget.PARTNER)
        }}>
        등록업체
      </button>

      <button
        style={{
          width: '170px',
          height: '100%',
          color: '#fff',
          borderRadius: '8px',
          background: orderTarget === EOrderTarget.CLOSEST ? '#ff8571' : undefined,
        }}
        onClick={() => {
          onChange(EOrderTarget.CLOSEST)
        }}>
        가장 가까운 구급차
      </button>
    </div>
  )
}

export default OrderTargetSwitch
