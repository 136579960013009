import SideBar from './SideBar'
import ManuBar from './ManuBar'
import { Outlet } from 'react-router-dom'
// import AuthGuard from './AuthGuard'
// import OrganizationGuard from './OrganizationGuard'
// import PlaceGuard from './PlaceGuard'

export default function Layout() {
  return (
    <div>
      <div className="flex h-screen bg-gray-100">
        <SideBar />
        <div className="flex flex-col flex-1 overflow-auto">
          <ManuBar />
          <Outlet />
        </div>
      </div>
      {/* <div className="flex items-center justify-center text-white bg-gray-800 h-96">
        footer
      </div> */}
      {/* <OrganizationGuard /> */}
      {/* <AuthGuard /> */}
      {/* <PlaceGuard /> */}
    </div>
  )
}
