import React, { useCallback, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../state/store'
import { loginAsync, selectIsLoggedIn } from '../state/userSlice'

const AuthGuard = () => {
  const isLoggedIn = useAppSelector(selectIsLoggedIn)
  const [id, setId] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const dispatch = useAppDispatch()

  const onSubmit = useCallback(
    (e: any) => {
      e.preventDefault()
      if (id && password) dispatch(loginAsync({ id, password }))
      setId('')
      setPassword('')
    },
    [dispatch, id, password],
  )

  if (isLoggedIn) return null
  return (
    <div className="fixed top-0 bottom-0 left-0 right-0 bg-[#000000d6] z-[60] flex flex-col items-center justify-center">
      <div className="flex flex-col bg-white rounded p-9">
        <h1 className="mb-3 text-2xl text-center">구출이 로그인</h1>

        <form onSubmit={e => onSubmit(e)}>
          <div className="">
            <label className="text-xs text-gray-700" htmlFor="id">
              공용 아이디{' '}
              <span className="text-white bg-gray-400 w-[1.125em] h-[1.125em] inline-block text-center rounded-full">
                ?
              </span>
            </label>
            <input
              type="text"
              id="id"
              className="w-full p-2 border border-gray-400 rounded focus:outline-none"
              value={id}
              onChange={e => setId(e.target.value)}
              autoComplete="off"
            />
          </div>

          <div className="">
            <label className="text-xs text-gray-700" htmlFor="password">
              공용 비밀번호
            </label>
            <input
              type="password"
              id="password"
              className="w-full p-2 border border-gray-400 rounded focus:outline-none"
              value={password}
              onChange={e => setPassword(e.target.value)}
              autoComplete="off"
            />
          </div>

          <div className="mt-4">
            <button className="w-full py-2 text-white bg-blue-500 rounded-md">로그인</button>
          </div>
        </form>

        {/* NOTE: (20240409 - 김현우) 병원웹 소셜로그인은 주석처리 해달라는 요청 반영 (노션 요청사항 참고) */}
        {/* <div className="mt-6 mb-3">
          <h2 className="w-full text-center border-b-2 line leading-[0.1em] mt-[10px] mb-[20px]">
            <span className="bg-white px-[10px]">또는</span>
          </h2>
        </div>

        <div className="mb-5">
          <a
            className="block py-2 text-center bg-[#41CE60] rounded-md mt-3 w-full text-white"
            href={`${process.env.REACT_APP_API_URL}/auth/naver/hospital`}>
            naver로 계속하기
          </a>
          <a
            className="block py-2 text-center bg-[#F8E814] rounded-md mt-3 w-full text-black"
            href={`${process.env.REACT_APP_API_URL}/auth/kakao/hospital`}>
            kakao로 계속하기
          </a>
          <a
            className="block py-2 text-center bg-[#4484F4] rounded-md mt-3 w-full text-white"
            href={`${process.env.REACT_APP_API_URL}/auth/google/hospital`}>
            google로 계속하기
          </a>
        </div> */}
      </div>
    </div>
  )
}

export default AuthGuard

// https://choowiki.com/24#:~:text=react%20%ED%94%84%EB%A1%9C%EC%A0%9D%ED%8A%B8%EC%97%90%EC%84%9C%20%EC%A3%BC%EC%86%8C%EA%B2%80%EC%83%89,%EB%8F%99%EC%9E%91%EB%90%98%EB%AF%80%EB%A1%9C%20%EA%B0%80%EC%9E%A5%20%EA%B0%84%ED%8E%B8%ED%95%98%EB%8B%A4.
