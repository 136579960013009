import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from '../api/axios'
import KakaoPlace from '../types/kakaoPlace'
import Order from '../types/Order'
import Place from '../types/Place'
import { RootState } from './store'

export const converToPlace = (from: KakaoPlace) => {
  const { x, y, place_name, address_name, road_address_name } = from
  const place: Place = {
    name: place_name,
    type: 'hospital',
    address: address_name,
    roadAddress: road_address_name,
    latitude: y,
    longitude: x,
  }

  return place
}

export interface OrderState {
  destination?: Place
  departure?: Place
  phoneNumber: string
  patientName: string
  patientPhoneNumber: string
  companionName: string
  companionPhoneNumber: string
  description: string
  gear: string
  etc: string
  orders: Order[]
  status: 'idle' | 'loading'
}

const initialState: OrderState = {
  departure: JSON.parse(localStorage.getItem('departure') ?? 'null') ?? undefined,
  destination: undefined,
  phoneNumber: '',
  patientName: '',
  patientPhoneNumber: '',
  companionName: '',
  companionPhoneNumber: '',
  description: '',
  gear: '',
  etc: '',
  orders: [],
  status: 'idle',
}

export const fetchOrdersAsync = createAsyncThunk('order/fetchOrdersAsync', async () => {
  const res = await axios.get('/order?listNum=1')
  return res.data
})

export const fakeOrderAsync = createAsyncThunk('order/requestOrderAsync', async (_, api) => {
  // !d2 || !d1 || !clientPhoneNumber || !patientName || !patientPhoneNumber
  const order = {
    departure: { id: 1 },
    destination: { id: 2 },
    phoneNumber: '010-7180-9266',
    patientName: '홍길동',
    patientPhoneNumber: '010-7231-3805',
  }
  const response = await axios.post('/order/request', order)

  api.dispatch(resetOrder())
  api.dispatch(fetchOrdersAsync())
  return response.data
})

export const requestOrderAsync = createAsyncThunk('order/requestOrderAsync', async (_, api) => {
  const state = api.getState() as RootState
  const order = { ...state.order } as Partial<OrderState>
  delete order.orders

  console.log({ order })

  const response = await axios.post('/order/request', order)

  api.dispatch(resetOrder())
  api.dispatch(fetchOrdersAsync())
  return response.data
})

export const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setDestination: (state, action: PayloadAction<KakaoPlace | undefined>) => {
      state.destination = action.payload ? converToPlace(action.payload) : action.payload
    },
    setDeparture: (state, action: PayloadAction<KakaoPlace | undefined>) => {
      state.departure = action.payload ? converToPlace(action.payload) : action.payload

      if (action.payload)
        localStorage.setItem('departure', JSON.stringify(converToPlace(action.payload)))
    },
    setPhoneNumber: (state, action: PayloadAction<string>) => {
      state.phoneNumber = action.payload
    },
    resetOrder: state => {
      state.destination = undefined
      state.phoneNumber = ''
      state.patientName = ''
      state.patientPhoneNumber = ''
      state.companionName = ''
      state.companionPhoneNumber = ''
      state.description = ''
      state.gear = ''
      state.etc = ''
    },
    setOrder: (state, action: PayloadAction<Partial<OrderState>>) => {
      Object.assign(state, addHyphen(action.payload))
    },
  },
  extraReducers: builder => {
    builder
      .addCase(requestOrderAsync.pending, state => {
        state.status = 'loading'
      })
      .addCase(requestOrderAsync.fulfilled, (state, action) => {
        console.log(action.payload)
        state.status = 'idle'
      })
      .addCase(requestOrderAsync.rejected, state => {
        state.status = 'idle'
      })
      .addCase(fetchOrdersAsync.fulfilled, (state, action) => {
        state.orders = action.payload
      })
  },
})

const addHyphen = (order: Partial<OrderState>) => {
  const phoneNumber = order.patientPhoneNumber
    ? order.patientPhoneNumber
    : order.companionPhoneNumber
    ? order.companionPhoneNumber
    : undefined

  let converted = phoneNumber

  if (phoneNumber?.length === 4 && phoneNumber?.startsWith('01')) {
    converted = phoneNumber?.replace(/(\d{3})(\d{1})/, '$1-$2')
  }

  if (phoneNumber?.length === 9 && phoneNumber?.startsWith('01')) {
    converted = phoneNumber?.replace(/(\d{3}-)(\d{4})(\d+)/, '$1$2-$3')
  }

  if (phoneNumber?.length === 10) {
    converted = phoneNumber?.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
  }

  if (phoneNumber?.length === 11) {
    converted = phoneNumber?.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3')
  }

  if (phoneNumber?.length === 13) {
    converted = phoneNumber?.replace(/-/g, '').replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3')
  }

  const regex = /^[0-9\b -]{0,13}$/

  if (phoneNumber && !regex.test(phoneNumber)) {
    return {}
  }

  if (order.patientPhoneNumber) order.patientPhoneNumber = converted
  if (order.companionPhoneNumber) order.companionPhoneNumber = converted

  return order
}

export const { setDestination, setDeparture, setPhoneNumber, resetOrder, setOrder } =
  orderSlice.actions

export const selectOrder = (state: RootState) => state.order
export const selectOrders = (state: RootState) => state.order.orders
export const selectDestination = (state: RootState) => state.order.destination
export const selectDeparture = (state: RootState) => state.order.departure
export const selectPhoneNumber = (state: RootState) => state.order.phoneNumber
export const selectIsOrderReady = (state: RootState) =>
  state.order.departure &&
  state.order.destination &&
  state.order.phoneNumber &&
  state.order.patientName &&
  state.order.description

export default orderSlice
