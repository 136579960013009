import React from 'react'

const Loader = () => {
  return (
    <div className="flex items-center justify-center min-h-screen text-white bg-black">
      loading.....
    </div>
  )
}

export default Loader
