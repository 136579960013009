import axios from 'axios'
import { useEffect, useState } from 'react'
import { CustomOverlayMap, Map, MapMarker, MapTypeId } from 'react-kakao-maps-sdk'
import { selectDeparture, selectDestination } from '../state/orderSlice'
import { useAppSelector } from '../state/store'
import { selectCoord } from '../state/userSlice'
import { KakaoMobility } from '../types/KakaoMobility'
import { Driver, OrderStatus } from '../types/Order'
import driverMarker from '../asset/driver_marker.png'
import { numberWithCommas } from '../lib/helper'

const KakaoMap = ({ className, map, setMap }: { className: string; map: any; setMap: any }) => {
  const coord = useAppSelector(selectCoord)
  const destination = useAppSelector(selectDestination)
  const departure = useAppSelector(selectDeparture)
  const [showTraffic, setShowTraffic] = useState(false)
  const [driver, setDriver] = useState<Driver>()
  const [estimateTimeOfArrival, setEstimateTimeOfArrival] = useState(0)
  const user = useAppSelector(s => s.user)
  const location = useAppSelector(s => s.location)

  useEffect(() => {
    if (destination && departure) {
      const maxY = Math.max(destination.latitude, departure.latitude)
      const minY = Math.min(destination.latitude, departure.latitude)
      const maxX = Math.max(destination.longitude, departure.longitude)
      const minX = Math.min(destination.longitude, departure.longitude)
      const sw = new kakao.maps.LatLng(minY, minX)
      const ne = new kakao.maps.LatLng(maxY, maxX)
      const bounds = new kakao.maps.LatLngBounds(sw, ne)

      map?.panTo(bounds)

      return
    }

    map?.setLevel(6)

    if (destination && !departure)
      map?.panTo(new kakao.maps.LatLng(destination.latitude, destination.longitude))

    if (departure && !destination)
      map?.panTo(new kakao.maps.LatLng(departure.latitude, departure.longitude))

    if (!departure && !destination && coord)
      map?.panTo(new kakao.maps.LatLng(coord.latitude, coord.longitude))
  }, [destination, departure, map, coord])

  useEffect(() => {
    if (location.driver.id < 0) return setDriver(undefined)
    const ox = location.driver.coord.longitude
    const oy = location.driver.coord.latitude
    const dx = Number(user.organization?.coordinate.longitude)
    const dy = Number(user.organization?.coordinate.latitude)

    const fetchDistTime = async () => {
      const res = await axios.get<{ routes: KakaoMobility[] }>(
        `https://apis-navi.kakaomobility.com/v1/directions?origin=${ox},${oy}&destination=${dx},${dy}&summary=true`,
        {
          headers: {
            Authorization: `KakaoAK ${process.env.REACT_APP_KAKAO_API_KEY}`,
            'Content-Type': 'application/json',
          },
        },
      )
      setEstimateTimeOfArrival(res.data.routes[0].summary.duration)
      setDriver(location.driver)
    }

    fetchDistTime()
  }, [location, user.organization?.coordinate.latitude, user.organization?.coordinate.longitude])

  useEffect(() => {
    localStorage.setItem(
      'coord',
      JSON.stringify({
        latitude: Number(user.organization?.coordinate.latitude),
        longitude: Number(user.organization?.coordinate.longitude),
      }),
    )
  }, [user.organization?.coordinate.latitude, user.organization?.coordinate.longitude])

  return (
    <div className={className + ` rounded-lg`}>
      <Map
        center={{
          lat: Number(user.organization?.coordinate.latitude),
          lng: Number(user.organization?.coordinate.longitude),
        }}
        isPanto
        className="w-full h-full"
        onCreate={map => setMap(map)}
        level={6}>
        {/* <ZoomControl position={kakao.maps.ControlPosition.BOTTOMRIGHT} /> */}

        {showTraffic && <MapTypeId type={kakao.maps.MapTypeId.TRAFFIC} />}

        {driver && (
          <>
            <MapMarker // 인포윈도우를 생성하고 지도에 표시합니다
              position={{ lat: driver.coord.latitude, lng: driver.coord.longitude }}
              image={{
                src: driverMarker, // 마커이미지의 주소입니다
                size: {
                  width: 40,
                  height: 46,
                },
              }}
            />
            <CustomOverlayMap
              position={{ lat: driver.coord.latitude, lng: driver.coord.longitude }}
              yAnchor={1}>
              <div className="flex-1 flex flex-row bg-white mt-[-110px] ml-[-30px] rounded-xl overflow-hidden">
                <div
                  className={`bg-red py-2 w-16 ${
                    location.order.status === OrderStatus.LOADED
                      ? 'flex items-center justify-center'
                      : null
                  }`}>
                  {location.order.status !== OrderStatus.LOADED && (
                    <>
                      <p className="text-xs text-white text-center">예상도착</p>
                      <p className="text-xs font-bold mt-1 text-white text-center">
                        {convertTime(estimateTimeOfArrival)} 후
                      </p>
                    </>
                  )}

                  {location.order.status === OrderStatus.LOADED && (
                    <p className="text-xs text-white">환자 이송중</p>
                  )}
                </div>
                <div className="bg-white py-2 w-32">
                  <p className="text-sm font-bold text-center">{driver.name} 드라이버</p>
                  <p className="text-xs text-center">{driver.phoneNumber} </p>
                </div>
                <div className="bg-red py-2 w-16">
                  <p className="text-xs text-white text-center">예상금액</p>
                  <p className="text-xs font-bold mt-1 text-white text-center">
                    {numberWithCommas(location.order.expectedFee)}
                  </p>
                </div>
              </div>
            </CustomOverlayMap>
          </>
        )}

        <MapMarker
          position={{ lat: coord.latitude, lng: coord.longitude }}
          image={{
            size: { width: 25, height: 25 },
            src: 'https://upload.wikimedia.org/wikipedia/commons/thumb/e/ee/Red_Cross_icon.svg/600px-Red_Cross_icon.svg.png',
          }}></MapMarker>

        {destination && (
          <MapMarker
            position={{ lat: destination.latitude, lng: destination.longitude }}
            onClick={() => {
              map?.setLevel(6)
              map?.panTo(new kakao.maps.LatLng(destination.latitude, destination.longitude))
            }}
          />
        )}

        {departure && (
          <MapMarker
            position={{
              lat: departure.latitude,
              lng: departure.longitude,
            }}
            onClick={() => {
              map?.setLevel(6)
              map?.panTo(new kakao.maps.LatLng(departure.latitude, departure.longitude))
            }}
          />
        )}
      </Map>

      <button
        className="absolute top-4 right-3 bg-gray-500 bg-opacity-50 z-[3] w-5 h-5 rounded-full"
        onClick={() => map?.panTo(new kakao.maps.LatLng(coord.latitude, coord.longitude))}
      />

      <button
        className="absolute top-10 right-3 bg-green-500 bg-opacity-50 z-[3] w-5 h-5 rounded-full"
        onClick={() => setShowTraffic(s => !s)}
      />
    </div>
  )
}

export default KakaoMap

export function convertTime(time: number): string {
  if (time <= 60) return '잠시'
  let hour = Math.floor(time / 3600)
  let minute = Math.floor((time % 3600) / 60)
  if (hour > 0) return `${hour}시 ${minute}분`
  return `${minute}분`
}
