import {
  // fakeOrderAsync,
  requestOrderAsync,
  selectIsOrderReady,
  selectOrder,
  setOrder,
} from '../state/orderSlice'
import { useAppDispatch, useAppSelector } from '../state/store'
import DepartureSearch from './DepartureSearch'
import DestinationSearch from './DestinationSearch'
import PhoneNumberInput from './PhoneNumberInput'
import TextInput from './TextInput'

export default function SideBar() {
  const isOrderReady = useAppSelector(selectIsOrderReady)
  const order = useAppSelector(selectOrder)
  const status = order.status

  const {
    patientName,
    patientPhoneNumber,
    description,
    gear,
    etc,
    departure,
    destination,
    phoneNumber,
  } = order

  const isPatientNameReady = departure && destination && phoneNumber
  const isPatientPhoneNumberReady = isPatientNameReady && patientName

  const dispatch = useAppDispatch()

  return (
    <section className="relative w-96 px-5 overflow-auto bg-white">
      {status === 'loading' && (
        <div className="absolute top-0 bottom-0 left-0 right-0 text-white bg-black z-[100] flex items-center justify-center opacity-80">
          loading...
        </div>
      )}
      {/* <div className="">
        <button onClick={() => dispatch(fakeOrderAsync())}>fake</button>
      </div> */}

      <div className="sticky top-0 flex flex-col pt-3 pb-10">
        <DepartureSearch className="relative z-[30]" />
        <PhoneNumberInput className="mt-1" disabled={false} />
        <DestinationSearch className="relative mt-3" />
        <TextInput
          isNecessary
          className="mt-3"
          label="환자명"
          id="patientName"
          value={patientName}
          setValue={e => dispatch(setOrder({ patientName: e.target.value }))}
          disabled={!isPatientNameReady}
        />
        <TextInput
          // isNecessary
          className="mt-3"
          label="환자 연락처"
          id="patientPhoneNumber"
          value={patientPhoneNumber}
          setValue={e => dispatch(setOrder({ patientPhoneNumber: e.target.value }))}
          disabled={!isPatientPhoneNumberReady}
        />

        <TextInput
          isNecessary
          className="mt-6"
          label="전원 사유"
          id="description"
          value={description}
          setValue={e => dispatch(setOrder({ description: e.target.value }))}
          disabled={!isPatientPhoneNumberReady}
        />
        <TextInput
          className="mt-3"
          label="이송시 필요 장비"
          id="gear"
          value={gear}
          setValue={e => dispatch(setOrder({ gear: e.target.value }))}
          disabled={!isPatientPhoneNumberReady}
        />
        <div className="mt-3">
          <label htmlFor="etc" className="text-xs text-gray-700">
            기타 요청사항
          </label>
          <textarea
            className="w-full p-2 border border-gray-400 rounded resize-none focus:outline-none"
            rows={3}
            id="etc"
            value={etc}
            onChange={e => dispatch(setOrder({ etc: e.target.value }))}
            disabled={!isPatientPhoneNumberReady}
          />
        </div>
        <div className="mt-3 text-xs text-gray-400">
          <span className="text-red">*</span> 표시된 것은 필수항목 입니다
        </div>
        {/* <TextInput
          className="mt-3"
          label="기타 요청사항"
          id="etc"
          value={etc}
          setValue={e => dispatch(setOrder({ etc: e.target.value }))}
          disabled={!isOrderReady}
        /> */}

        <div className="w-full self-end mt-6">
          <button
            className={`w-full py-2 text-white rounded-md ${
              isOrderReady ? 'bg-red' : 'bg-gray-200'
            }`}
            disabled={!isOrderReady}
            onClick={() => dispatch(requestOrderAsync())}>
            구급차 호출
          </button>
        </div>
      </div>
    </section>
  )
}
