import React, { Dispatch, SetStateAction, useRef } from 'react'
import DatePicker from 'react-datepicker'
import ko from 'date-fns/locale/ko'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/free-solid-svg-icons'

import 'react-datepicker/dist/react-datepicker.css'
import dayjs from 'dayjs'

interface Props {
  date: Date
  changeDate: Dispatch<SetStateAction<Date>>
  setSelectPage: Dispatch<SetStateAction<number>>
}
function CustomCalendar({ date, changeDate, setSelectPage }: Props) {
  const datePickerRef = useRef<any>(null)
  const openDatepicker = () => datePickerRef.current?.setOpen(true)
  const today = new Date()

  return (
    <div className="relative mx-3 flex flex-row ">
      <DatePicker
        ref={datePickerRef}
        className="border h-10 w-32 pl-2 text-sm outline-none rounded-tl-md rounded-bl-md text-gray-500"
        selected={date}
        dateFormat={'yyyy-MM-dd'}
        onChange={(date: Date) => {
          console.log(dayjs(date).format('YYYY-MM-DD'))
          changeDate(date)
          setSelectPage(1)
        }}
        maxDate={today}
        locale={ko}
      />
      <FontAwesomeIcon
        onClick={openDatepicker}
        className=" bg-gray-200 p-[12px] text-gray-400 cursor-pointer rounded-tr-md rounded-br-md"
        icon={faCalendar}
      />
    </div>
  )
}

export default CustomCalendar
