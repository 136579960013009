import { useState } from 'react'
import KakaoMap from './KakaoMap'
import OrdersPanel from './OrdersPanel'

const MapPage = () => {
  const [map, setMap] = useState<kakao.maps.Map>()

  return (
    <div className=" px-5 flex-row flex items-start gap-5 justify-center h-[88vh] ">
      <div className="flex h-[88vh] min-w-[600px] w-2/3">
        <div className="flex flex-col flex-1 w-full bg-white rounded-lg">
          <div className="flex justify-between p-5 border-b">
            <div className="flex items-center gap-2">
              <h3 className="">인근 구급차 현황</h3>
              {/* <button className="px-2 py-1 text-xs text-white bg-blue-500 rounded">
                협력사만
              </button> */}
            </div>
            <div className="flex items-center gap-3">
              <div className="flex items-center gap-1">
                <div className="w-2 h-2 bg-blue-500 rounded-full" />
                <div className="">구급차</div>
              </div>
              <div className="flex items-center gap-1">
                <div className="w-2 h-2 bg-red rounded-full" />
                <div className="">병원</div>
              </div>
            </div>
          </div>
          <KakaoMap className="relative flex-1 m-5 w-ful" map={map} setMap={setMap} />
        </div>
        {/* <OngoingPanel className="w-64 mx-5 bg-white rounded-lg" map={map} /> */}
      </div>
      <OrdersPanel />
    </div>
  )
}

export default MapPage
