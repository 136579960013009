import React, { useEffect, useRef, useState } from 'react'
import { selectOrder, setDestination } from '../state/orderSlice'
import { useAppDispatch, useAppSelector } from '../state/store'
import { selectCoord } from '../state/userSlice'
import KakaoPlace from '../types/kakaoPlace'

type Props = { className: string }

const DestinationSearch = ({ className }: Props) => {
  const [query, setQuery] = useState('')
  const [index, setIndex] = useState(-1)
  const [places, setPlaces] = useState<KakaoPlace[]>([])
  const [timer, setTimer] = useState<any>(null)

  const dispatch = useAppDispatch()
  const order = useAppSelector(selectOrder)
  const { destination, departure, phoneNumber } = order
  const isReady = !destination && departure && phoneNumber
  const coord = useAppSelector(selectCoord)

  useEffect(() => {
    if (destination) {
      setQuery('')
    }
  }, [destination])

  useEffect(() => {
    setPlaces([])
    setIndex(-1)

    if (query.trim() === '') {
      clearTimeout(timer)
      return
    }
    searchSubs()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  const searchSubs = () => {
    clearTimeout(timer)
    setTimer(
      setTimeout(() => {
        const places = new kakao.maps.services.Places()
        const options = coord ? { x: coord.longitude, y: coord.latitude } : {}
        const callback = (
          result: kakao.maps.services.PlacesSearchResult,
          status: kakao.maps.services.Status,
        ) => {
          if (status === kakao.maps.services.Status.OK) {
            const top10 = result.filter((_, index) => index < 10) as unknown as KakaoPlace[]
            setPlaces(top10)
          }
        }

        places.keywordSearch(query, callback, options)
      }, 250),
    )
  }

  const inputRef = useRef<any>(null)
  useEffect(() => {
    if (isReady) inputRef.current.focus()
  }, [isReady])

  if (destination)
    return (
      <div className={className}>
        <label htmlFor="destination" className="text-xs text-gray-700">
          도착지 <span className="text-red">*</span>
        </label>
        <div className="w-full p-2 text-gray-500 border border-gray-400 rounded" id="destination">
          <div className="mb-1 leading-5 text-gray-700">{destination.name}</div>
          <div className="text-xs">{destination.roadAddress}</div>
          <div className="text-xs">{destination.address}</div>
          <div className="flex justify-end">
            <button
              className="px-2 py-1 text-xs text-white bg-gray-500 rounded-sm"
              onClick={() => dispatch(setDestination(undefined))}>
              재설정
            </button>
          </div>
        </div>
      </div>
    )

  return (
    <div className={className}>
      <label htmlFor="destination" className="text-xs text-gray-700">
        도착지 <span className="text-red">*</span>
      </label>
      <input
        ref={inputRef}
        disabled={!isReady}
        id="destination"
        type="text"
        placeholder="장소, 주소, 전화번호 검색"
        className="w-full p-2 border border-gray-400 rounded focus:outline-none placeholder:text-sm"
        value={query}
        onChange={e => setQuery(e.target.value)}
        onFocus={e => e.target.select()}
        onKeyDown={e => {
          if (e.nativeEvent.isComposing) return
          if (e.key === 'ArrowDown') {
            e.preventDefault()
            if (index < places.length - 1) setIndex(i => i + 1)
          }

          if (e.key === 'ArrowUp') {
            e.preventDefault()
            if (index > 0) setIndex(i => i - 1)
          }

          if (e.key === 'Tab') {
            e.preventDefault()

            if (e.shiftKey && index > 0) {
              setIndex(i => i - 1)
              return
            }

            if (index < places.length - 1) setIndex(i => i + 1)
          }

          if (e.key === 'Enter') {
            e.preventDefault()
            const dest = places[index]
            dispatch(setDestination(dest))
          }
        }}
      />
      {places.length > 0 && (
        <div className="absolute w-full bg-white border border-gray-400 top-16">
          {places.map((sub, idx) => (
            <button
              key={sub.id}
              id={`sub-${idx}`}
              onClick={e => {
                e.preventDefault()
                const dest = places[idx]
                dispatch(setDestination(dest))
              }}
              className={`block w-full p-2 tart hover:bg-gray-200 focus:bg-gray-200 focus:outline-none hover:cursor-pointer ${
                index === idx ? 'bg-gray-200' : ''
              }`}>
              <div className="text-sm text-left">{sub.place_name}</div>
              <div className="text-xs text-left">{sub.road_address_name}</div>
              <div className="text-xs text-left">{sub.phone}</div>
            </button>
          ))}
        </div>
      )}
    </div>
  )
}

export default DestinationSearch
