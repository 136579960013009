import { Menu } from '@headlessui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

import { useAppDispatch, useAppSelector } from '../state/store'
import { logoutAsync, selectIsLoggedIn, selectUser } from '../state/userSlice'
import { Link } from 'react-router-dom'
import { fakeOrderAsync } from '../state/orderSlice'
import logo from '../asset/logo.png'
import { EOrderTarget } from '../lib/enum'
import { useEffect, useState } from 'react'
import OrderTargetSwitch from './OrderTargetSwitch'
import axios from '../api/axios'

const ManuBar = () => {
  const user = useAppSelector(selectUser)
  const isLoggedIn = useAppSelector(selectIsLoggedIn)
  const dispatch = useAppDispatch()

  const [currentOrderTarget, setCurrentOrderTarget] = useState(EOrderTarget.CLOSEST)

  useEffect(() => {
    setCurrentOrderTarget(user.organization.orderTarget)
  }, [user])

  return (
    <div className="sticky top-0 flex items-center justify-between h-16 px-4 py-2 bg-background z-[31] mb-5 rounded-bl-lg">
      <div className="flex items-center justify-between gap-2" style={{ width: '60%' }}>
        <div className="flex items-center gap-2">
          <Link to="/" className="">
            <img className="w-40" src={logo} alt="logo" />
          </Link>
        </div>

        <OrderTargetSwitch
          orderTarget={currentOrderTarget}
          onChange={t => {
            axios
              .put('/organization/update', {
                id: user.organization.id,
                orderTarget: t,
              })
              .then(res => {
                if (!!res?.data) setCurrentOrderTarget(t)
              })
              .catch(e => {})
          }}
        />
      </div>

      {isLoggedIn && (
        <div className="flex items-center gap-2">
          {/* <div className="">
            <FontAwesomeIcon icon={faBell} />
          </div> */}

          {/* <div className="text-sm">
            <button className="ont-semibold">{user.name}</button>
          </div> */}

          {/* dropdown */}
          {/* https://headlessui.dev/react/menu */}
          <Menu as="div" className="mt-5 relative inline-block text-left z-[40]">
            <div className="">
              <Menu.Button>
                <div className="text-sm mr-4">
                  <button className="ont-semibold text-lg">
                    {user.name}{' '}
                    <FontAwesomeIcon
                      icon={faChevronDown}
                      className="text-sm mb-[2px] ml-1"
                      color="#bbb"
                    />{' '}
                  </button>
                </div>
              </Menu.Button>
            </div>

            <Menu.Items className="fixed flex flex-col w-64 origin-top-right bg-white border divide-y divide-gray-300 top-16 right-4">
              <div className="flex items-center gap-2 p-4">
                {/* <div className="">
                  <img
                    src={user.profileImage}
                    alt={user.name}
                    className="w-10 h-10 rounded-full"
                  />
                </div> */}
                <div className="flex flex-col flex-1">
                  <div className="">{user.name}</div>
                  {/* <div className="flex gap-2">
                    <button className="text-blue-600">계정관리</button>
                  </div> */}
                </div>
              </div>

              {/* <div className="flex flex-col">
                <Menu.Item>
                  {({ active }) => (
                    <div
                      className={`
                      ${active && 'bg-gray-200'}
                      px-4 py-2
                    `}>
                      <button className="">😄 {user?.name}</button>
                    </div>
                  )}
                </Menu.Item>

                <Menu.Item>
                  {({ active }) => (
                    <div
                      className={`
                      ${active && 'bg-gray-200'}
                      px-4 py-2
                    `}>
                      <button className="">{user?.id}</button>
                    </div>
                  )}
                </Menu.Item>

                <Menu.Item>
                  {({ active }) => (
                    <div
                      className={`
                      ${active && 'bg-gray-200'}
                      px-4 py-2
                    `}>
                      <button className="">{user?.email ? user.email : 'no email'}</button>
                    </div>
                  )}
                </Menu.Item>
              </div> */}

              <div className="flex flex-col">
                {/* <Menu.Item>
                  {({ active }) => (
                    <button className={`${active && 'bg-gray-200'} px-4 py-2 w-full flex`}>
                      <div className="flex-1 text-left">테마</div>
                      <div className="">{'>'}</div>
                    </button>
                  )}
                </Menu.Item> */}

                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`${active && 'bg-gray-200'} px-4 py-2 w-full flex`}
                      onClick={() => dispatch(logoutAsync())}>
                      로그아웃
                    </button>
                  )}
                </Menu.Item>

                {/* <div className="px-4 py-2">고객센터</div> */}
                {/* <div className="px-4 py-2">설정</div>
                <div className="px-4 py-2">계정전환</div> */}
              </div>
            </Menu.Items>
          </Menu>
        </div>
      )}
    </div>
  )
}

export default ManuBar
