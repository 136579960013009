import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import Order, { Driver } from '../types/Order'
import { RootState } from './store'

export type Location = {
  driver: Driver
  order: Partial<Order>
}

const initialState: Location = {
  driver: {
    id: -1,
    role: '',
    name: '',
    email: '',
    phoneNumber: '',
    licenseNumber: undefined,
    isp: '',
    identifier: undefined,
    profileImage: '',
    coord: {
      latitude: 0,
      longitude: 0,
    },
    location: '',
    status: '',
    deletedAt: undefined,
  },
  order: {
    id: -1,
    createdAt: '',
    updatedAt: '',
    driverMatchedAt: '',
    departedAt: '',
    loadedAt: '',
    arrivedAt: '',
    feeCaculatedAt: '',
    completedAt: '',
    clientPhoneNumber: '',
    patientName: '',
    patientPhoneNumber: '',
    companionName: '',
    companionPhoneNumber: '',
    description: '',
    gear: '',
    etc: '',
    status: '',
    invoice: undefined,
    expectedFee: 0,
  },
}

const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    setLocation: (state, action: PayloadAction<Partial<Location>>) => {
      Object.assign(state, action.payload)
    },
    resetLocation: state => {
      Object.assign(state, initialState)
    },
  },
})

export const { setLocation, resetLocation } = locationSlice.actions
export const getCurrentOrder = (state: RootState) => state.location.order

export default locationSlice
