import useAxios from './hooks/useAxios'
import useAuth from './hooks/useAuth'
import { Route, Routes } from 'react-router-dom'

import Layout from './components/Layout'
import MapPage from './components/MapPage'

import Loader from './components/Loader'
import NotFoundPage from './pages/NotFoundPage'

import dayjs from 'dayjs'
import 'dayjs/locale/ko'
import relativeTime from 'dayjs/plugin/relativeTime'
import AuthGuard from './components/AuthGuard'
import CallHistoryPags from './pages/CallHistoryPags'
dayjs.extend(relativeTime)
dayjs.locale('ko')

function App() {
  useAxios()
  const { isLoading, isLoggedIn } = useAuth()

  if (isLoading) return <Loader />

  if (!isLoggedIn) return <AuthGuard />

  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="/" element={<MapPage />} />
        <Route path="/call-history" element={<CallHistoryPags />} />
      </Route>

      <Route path="/*" element={<NotFoundPage />} />
    </Routes>
  )
}

export default App
