import { useEffect, useMemo, useRef, useState } from 'react'
import { fetchOrdersAsync, selectOrders } from '../state/orderSlice'
import { useAppDispatch, useAppSelector } from '../state/store'
import { selectIsLoggedIn } from '../state/userSlice'
import dayjs from 'dayjs'
import { resetLocation, setLocation } from '../state/locationSlice'
import Order, { Driver, OrderStatus } from '../types/Order'
import { Link } from 'react-router-dom'
import axios from '../api/axios'

const OrdersPanel = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [sortedOrders, setSortedOrders] = useState<Order[]>([])

  const isLoggedIn = useAppSelector(selectIsLoggedIn)
  const orders = useAppSelector(selectOrders)
  const dispatch = useAppDispatch()
  const filter = ''
  const exceptionStatus = useMemo(
    () => [
      'no-driver',
      'pending',
      'started',
      'departed',
      'arrived',
      'fee-requested',
      'fee-calculated',
      'completed',
      'canceled',
    ],
    [],
  )

  const refRefreshIntervalId = useRef<NodeJS.Timer | null>(null)

  const handleDriverLocation = async (o: Order) => {
    if (!o.driver) return

    try {
      const res = await axios.get<{ order: Order; driver: Driver }>(
        `/order/status/${o.id}/${o.driver.id}`,
      )

      if (exceptionStatus.includes(res.data.order.status)) return dispatch(resetLocation())
      dispatch(setLocation({ driver: res.data.driver, order: res.data.order }))
    } catch (error) {
      console.log(error)
    }
  }

  const handleReRender = async () => {
    setIsLoading(true)
    await dispatch(fetchOrdersAsync())
    setTimeout(() => setIsLoading(false), 500)
  }

  const handleOrderCancel = async (order: Order) => {
    const isCheck = window.confirm('정말로 구급차 호출을 취소하시겠습니까?')

    if (!isCheck) return

    try {
      await axios.put('/order/cancel', { orderId: order.id })
      await dispatch(fetchOrdersAsync())
    } catch (error) {
      console.log(error)
    }
  }

  const handleOrderAgain = async (order: Order) => {
    if (!window.confirm('다시 호출하시겠습니까?')) return

    try {
      await axios.post('/order/again', { previousOrderId: order.id })
      await dispatch(fetchOrdersAsync())
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (isLoggedIn) dispatch(fetchOrdersAsync())
  }, [dispatch, isLoggedIn])

  useEffect(() => {
    setSortedOrders(
      [...orders]
        .filter(
          order =>
            order.destination.name.search(filter) > -1 ||
            order.patientName.search(filter) > -1 ||
            (order.patientPhoneNumber && order.patientPhoneNumber.search(filter) > -1),
        )
        .sort((a, b) => b.id! - a.id!)
        .slice(0, 5),
    )
  }, [orders])

  useEffect(() => {
    const targetStatus = [
      OrderStatus.LOADED,
      OrderStatus.DRIVER_MATCHED,
      OrderStatus.HERO_REQUESTED,
      OrderStatus.HERO_REJECTED,
      OrderStatus.PICKUP_REQUESTED,
      OrderStatus.PICKUP_REJECTED,
      OrderStatus.HERO_MATCHED,
      OrderStatus.HERO_PICKUPED,
      OrderStatus.PENDING,
    ]

    // sortedOrders가 변경될 때마다 '폴링해야 하는 order'가 있는지 확인하고
    const needRefresh = !!sortedOrders.find(order =>
      targetStatus.includes(order.status as OrderStatus),
    )

    // 기존의 interval은 우선 제거
    if (refRefreshIntervalId.current !== null) {
      clearInterval(refRefreshIntervalId.current)
      refRefreshIntervalId.current = null
    }

    // 폴링해야 하는 order가 있다면 setInterval
    if (needRefresh) refRefreshIntervalId.current = setInterval(handleReRender, 3000)
  }, [sortedOrders])

  if (!isLoggedIn) return null

  return (
    <div className=" w-1/3 min-w-[250px] h-full overflow-auto flex flex-1 flex-col justify-between ">
      <ul
        className={`flex  flex-col h-[80vh] overflow-y-auto ${
          sortedOrders.length === 0 && 'justify-center items-center'
        }`}>
        {sortedOrders.length > 0 ? (
          sortedOrders.map(order => (
            <li key={order.id} className="p-4 bg-white rounded-lg mb-3">
              <div className="flex-row flex justify-between">
                <h3 className="text-lg "> {order.patientName?.replace(/(.)(.)(.*)/, '$1*$3')} </h3>
                <p className="text-sm text-gray-500">
                  {dayjs().diff(dayjs(order.createdAt), 'hour') < 1
                    ? dayjs(order.createdAt).fromNow()
                    : dayjs().diff(dayjs(order.createdAt), 'hour') < 8
                    ? dayjs(order.createdAt).format('A h:mm')
                    : dayjs(order.createdAt).format('MM/DD HH:mm')}
                </p>
              </div>
              <div className="mt-2">
                <p className="text-sm text-gray-500 mt-1">이송병원 : {order.destination.name} </p>
                <p className="text-sm text-gray-500 mt-1">전원사유 : {order.description || '-'} </p>
                <p className="text-sm text-gray-500 mt-1">기타사항 : {order.etc || '-'}</p>
              </div>
              {(order.status === OrderStatus.LOADED ||
                order.status === OrderStatus.DRIVER_MATCHED ||
                order.status === OrderStatus.HERO_REQUESTED ||
                order.status === OrderStatus.HERO_REJECTED ||
                order.status === OrderStatus.PICKUP_REQUESTED ||
                order.status === OrderStatus.PICKUP_REJECTED ||
                order.status === OrderStatus.HERO_MATCHED ||
                order.status === OrderStatus.HERO_PICKUPED) && (
                <div className="mt-3 flex gap-4">
                  <button
                    onClick={() => handleDriverLocation(order)}
                    className="text-white bg-red py-2 flex-1 rounded-lg">
                    실시간 이송 상황 보기
                  </button>

                  <button
                    className="bg-[#939393] rounded-lg px-2 text-white"
                    onClick={() => handleOrderCancel(order)}>
                    취소
                  </button>
                </div>
              )}
              {(order.status === OrderStatus.PENDING ||
                order.status === OrderStatus.NO_DRIVER ||
                order.status === OrderStatus.CANCELLED) && (
                <div className="mt-3 flex gap-4">
                  <button
                    className="bg-gray-200 text-gray-500 py-2 flex-1 text-center rounded-lg"
                    onClick={() => handleDriverLocation(order)}>
                    {order.status === OrderStatus.PENDING
                      ? '호출중..'
                      : order.status === OrderStatus.CANCELLED
                      ? '호출취소'
                      : '드라이버가 없습니다.'}
                  </button>

                  {order.status === OrderStatus.PENDING && (
                    <button
                      className="bg-[#939393] rounded-lg px-2 text-white"
                      onClick={() => handleOrderCancel(order)}>
                      취소
                    </button>
                  )}

                  {(order.status === OrderStatus.NO_DRIVER ||
                    order.status === OrderStatus.CANCELLED) && (
                    <button
                      className="bg-[#939393] rounded-lg px-2 text-white"
                      onClick={() => handleOrderAgain(order)}>
                      다시호출
                    </button>
                  )}
                </div>
              )}
              {(order.status === OrderStatus.ARRIVED ||
                order.status === OrderStatus.FEE_REQUESTED ||
                order.status === OrderStatus.FEE_CALCULATED ||
                order.status === OrderStatus.COMPLETED) && (
                <div className="mt-3 flex-row flex items-center justify-between">
                  <button
                    className=" bg-gray-200 text-gray-500 py-2 w-3/4 rounded-lg text-base"
                    onClick={() => handleDriverLocation(order)}>
                    {order.driver?.organization?.name} ({order.driver?.organization?.phoneNumber})
                  </button>
                  <p className="text-green w-1/4 text-center">이송완료</p>
                </div>
              )}
            </li>
          ))
        ) : (
          <p className="text-gray-400">최근 이송 내역이 없습니다.</p>
        )}
      </ul>
      <div className=" flex flex-row gap-4 items-end justify-center">
        <div
          className="bg-red text-white w-1/3 text-center py-3 rounded-lg cursor-pointer"
          onClick={handleReRender}>
          {!isLoading && <p>새로고침</p>}

          {isLoading && (
            <div className="">
              <div className="mx-auto border-t-transparent border-solid animate-spin rounded-full border-white border-2 h-6 w-6"></div>
            </div>
          )}
        </div>

        <div className="w-2/3">
          <Link
            className="w-full bg-[#939393] text-center py-3 rounded-lg text-white font-bold flex-1 flex justify-center"
            to={'/call-history'}>
            전체 이송 내역 보기
          </Link>
        </div>
      </div>
    </div>
  )
}

export default OrdersPanel
